<template>
  <div class="container">
    <loading :active.sync="awaitResponse" 
              :is-full-page="fullPage"/>
    <navigation v-if="facilityConfig" v-bind:logo="'data:image/jpeg;base64,' + facilityConfig.facilityLogo"/>
    <v-container>
       <v-container>
        <v-row>
          <v-col cols="12" md="8"  offset-md="2" class="text-center">
          <h3>{{$t('commons.additionalServiceList')}}</h3>
          </v-col>
        </v-row>
        <v-row>
        <v-col cols="12" md="8"  offset-md="2">
          <v-combobox
          v-model="selectedServices"
          :items="forms"
          item-text="name"
          label="Select service"
          multiple
          chips
          @change="showServiceForm"
        ></v-combobox>
        </v-col>
        </v-row>
      </v-container>
      <v-row>
      <v-col cols="12" md="8" offset-md="2">
      <v-form v-model="valid" ref="form" @submit.prevent="onSubmit">
        <v-container v-for="item in forms" :key="item.id" >
          <v-card v-if="showServiceForm(item)" elevation="2" >
            <v-card-title>{{item.name}}</v-card-title>
            <v-card-subtitle><span v-html="item.description"></span></v-card-subtitle>
            <v-card-text>
              <v-container>
                  <v-jsf v-model="item.formModel" :schema="item.formSchema"/>
              </v-container>
            </v-card-text>
          </v-card>
        </v-container>
        <v-row>
          <v-col cols="6" class="d-flex justify-start">
            <v-btn @click="skip" style="background-color: white; border-color: white; color: rgb(217, 183, 103);">{{$t('commons.skip')}}</v-btn>
          </v-col>
           <v-col cols="6" class="d-flex justify-end">
             <v-btn class="mr-4" type="submit" style="background-color: rgb(217, 183, 103); border-color: rgb(217, 183, 103); color: white;">{{$t('commons.submit')}}</v-btn> 
          </v-col>
        </v-row>
      </v-form>
      </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VJsf from '@koumoul/vjsf/lib/VJsf.js'
import '@koumoul/vjsf/lib/VJsf.css'
import '@koumoul/vjsf/lib/deps/third-party.js'
import Navigation from '../components/Navigation';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  mounted() {
   this.loadForms();
  },
  components: {
    VJsf,
    Navigation,
    Loading,
  },
 computed: {
    ...mapGetters(["facilityConfig","loadedReservation"]),
   },
  data() {
    return {
      facilityLogo:null,
      valid: false,
      fullPage:true,
      awaitResponse: false,
      isLoading: false,
      forms:[],
      selectedServices:[]
    };
  },
  methods: {
    showServiceForm(code){
      return this.selectedServices.find(f => f.code == code.code)?true:false
    },
    onSubmit(){
      if(this.valid){
        this.forms.forEach(element => {
          if(element.formModel.__ob__.dep.subs.length > 0){
            console.log(element)
          }
        });
      }
    },
    skip(){
      
    },
    async loadForms(){
        console.log(this.$i18n.locale)
        let obj = new Object()
        obj.code = this.loadedReservation.facilityCode
        obj.language = this.$i18n.locale
        await this.$store.dispatch("getAuthToken", this.activeReservation).then(() =>{
          this.$store.dispatch("loadAdditionalForms", obj).then((res) =>{
            this.forms=res.data
        })
      })  
    }
  }
};
</script>
