<template>
    <v-row>
        <v-col cols="12" md="12">
            <p><span v-html="privacy.content"></span></p>
            <v-radio-group v-if="!privacy.mandatory" v-model="privacy.accepted" mandatory row>
                <v-radio :label="$t('commons.accept')" :value="true"></v-radio>
                <v-radio :label="$t('commons.deny')" :value="false"></v-radio>
            </v-radio-group>
            <v-checkbox v-else v-model="privacy.accepted" :rules="validateCheckbox" :label="$t('commons.accept')"></v-checkbox>
        </v-col>
    </v-row>
</template>

<script>
export default {
  props: ["privacy"],
  computed: {
    validateCheckbox () {
      return [this.privacy.accepted > 0 || this.$t('commons.requiredAcceptedField')]
    }
  },
};
</script>