import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import vuetify from './plugins/vuetify';
import Accompany from './components/Accompany.vue';
import Birthdaypicker from './components/Birthdaypicker.vue'
import Notifications from 'vue-notification'
import OtpInput from "@bachdgvn/vue-otp-input";
import VueFormWizard from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
import i18n from './i18n'
import ImageUploader from 'vuetify-image-upload-resize'
import LoadScript from 'vue-plugin-load-script';


Vue.component('accompany',Accompany);
Vue.component('birthdaypicker',Birthdaypicker);
Vue.component("v-otp-input", OtpInput);
Vue.use(Notifications)
Vue.use(VueFormWizard)
Vue.use(ImageUploader)
Vue.use(LoadScript)
Vue.use(VueTelInputVuetify, {
  vuetify,
});
axios.defaults.baseURL = process.env.VUE_APP_API_URL
Vue.config.productionTip = false
Vue.prototype.$http = axios;

localStorage.removeItem('token');
store.dispatch("getAuthToken").then(() =>
{
  new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
  }).$mount("#app");
})