<template>
  <v-container class="mb-12">
          <v-row>
            <v-col
              v-if="FieldsHelper.isVisible('reservationcode')"
              cols="12"
              sm="12"
              md="3"
            >
              <!-- Reservation Code -->
              <v-text-field
                v-model="reservation.reservationNumber"
                :rules="FieldsHelper.getRules('reservationcode')"
                :label="$t('reservationStep.reservationCode') + FieldsHelper.isMandatoryLabel('reservationcode')"
                readonly
                autocomplete="off"
                >Reservation Code</v-text-field
              >
            </v-col>
            <!-- Arrival Date Picker-->
            <v-col v-if="FieldsHelper.isVisible('arrivaldate')" cols="12" sm="12" md="3">
              <v-menu
                ref="menuArrivalDate"
                v-model="menuArrivalDate"
                disabled
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="computedArrivalDate"
                    :label="$t('reservationStep.arrivalDate')  + FieldsHelper.isMandatoryLabel('arrivaldate')"
                    persistent-hint
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    readonly
                    v-on="on"
                    autocomplete="off"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="reservation.arrivalDate"
                  no-title
                  @input="menuArrivalDate = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col v-if="FieldsHelper.isVisible('arrivaltime')" cols="12" sm="12" md="3">
              <v-dialog
                ref="dialog"
                v-model="modal2"
                :return-value.sync="reservation.arrivalTime"
                persistent
                width="290px"
              >
                <!-- Attival Time Picker -->
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :rules="FieldsHelper.getRules('arrivaltime')"
                    v-model="reservation.arrivalTime"
                    :label="$t('reservationStep.arrivalTime') + FieldsHelper.isMandatoryLabel('arrivaltime')" 
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    autocomplete="off"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="modal2"
                  v-model="reservation.arrivalTime"
                  full-width
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal2 = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog.save(reservation.arrivalTime)"
                  >
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </v-col>
            <v-col v-if="FieldsHelper.isVisible('nights')" cols="12" sm="12" md="3">
              <v-text-field
                v-model="reservation.nights"
                readonly
                :rules="FieldsHelper.getRules('nights')"
                hide-details
                type="number"
                :label="$t('reservationStep.nights') + FieldsHelper.isMandatoryLabel('nights')"
                autocomplete="off"
              ></v-text-field>
            </v-col>
          </v-row>
  </v-container>
</template>


<script>
  import FieldsHelper from '../helper/FieldsHelper'
  export default {
    props: ["reservation"],
    data: () => ({
      FieldsHelper,
      menuArrivalDate: false,
      modal2: false,
    }),
    computed: {
      computedArrivalDate() {
        return this.FieldsHelper.formatDate(this.reservation.arrivalDate);
      }
    },
  }
</script>