import Vue from 'vue'
import VueRouter from 'vue-router'
//import store from '../store'
import OtpCheck from '../views/OtpCheck.vue'
import Success from '../views/Success.vue'
import Reservation from '../views/Reservation.vue'
import AdditionalForms from '../views/AdditionalForms.vue'
import Error404 from '../views/error/404.vue'
import TransactionOtp from '../views/TransactionOtp.vue'
import Test from '../views/test.vue'

Vue.use(VueRouter)

const routes = [
 {
    path: '/',
    name: 'otpCheck',
    component: OtpCheck
  },
  {
    path: '/404',
    name: 'error404',
    component: Error404
  },
  {
    path: '/success',
    name: 'success',
    component: Success
  },
  {
    path: '/reservation',
    name: 'reservation',
    component: Reservation
  },
  {
    path: '/additionalForms',
    name: 'additionalForms',
    component: AdditionalForms
  },
  {
    path: '/transaction/:transactionId',
    name: 'transaction',
    component: TransactionOtp
  },
  {
    path: '/test',
    name: 'test',
    component: Test
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
