export default {
    buildFormData(formData, data, parentKey) {
        if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File) && !(data instanceof Array)) {
            Object.keys(data).forEach(key => {
                this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
            });
        } else {
            if(data instanceof Array){
                Object.keys(data).forEach(key => {
                    var compositeKey = parentKey +'['+key+']'
                    if (data[key]){
                        Object.keys(data[key]).forEach(val => {
                            if(data[key][val] != null){
                                formData.append(compositeKey+'.'+ val,  data[key][val]);
                                console.log(compositeKey + '-' + val + '-' + data[key]) 
                            }
                        });
                    }
                }); 
            }else{
                if(data != null){
                    const value = data == null ? null : data;
                    formData.append(parentKey, value);
                }
            }
        }
    }
}