import Vue from "vue";
import Vuex from "vuex";
import location from "./modules/location"
import reservation from './modules/reservation';
import facility from './modules/facility';
import authorization from './modules/auth';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    location,
    reservation,
    facility,
    authorization
  }
});
