<template>
    <v-container class="mb-12">
        <v-row no-gutters>
        <!-- First Name ------>
        <v-col v-if="FieldsHelper.isVisible('firstname')" cols="12" md="3">
            <v-text-field
            v-model="primaryProfile.firstName"
            :rules="FieldsHelper.getRules('firstname')"
            required
            :label="$t('personalStep.firstName') + FieldsHelper.isMandatoryLabel('firstname')"
            autocomplete="off"
            ></v-text-field>
        </v-col>
        <v-spacer></v-spacer>
        <!-- Last Name ------>
        <v-col v-if="FieldsHelper.isVisible('lastname')" cols="12" md="3">
            <v-text-field
            v-model="primaryProfile.lastName"
            :rules="FieldsHelper.getRules('lastname')"
            :label="$t('personalStep.lastName') + FieldsHelper.isMandatoryLabel('lastname')"
            autocomplete="off"
            >Last Name</v-text-field
            >
        </v-col>
        <v-spacer></v-spacer>
        <!-- Citizenship ------>
        <v-col v-if="FieldsHelper.isVisible('citizenship')" cols="12" md="4">
            <v-autocomplete
            :rules="FieldsHelper.getRules('citizenship')"
            v-model="primaryProfile.nationality"
            :items="countryList"
            item-text="countryName"
            item-value="countryCode"
            :label="$t('personalStep.citizenship') + FieldsHelper.isMandatoryLabel('citizenship')"
            autocomplete="off"
            ></v-autocomplete>
        </v-col>
        </v-row>
        <v-row no-gutters>
        <!-- Gender ------>
        <v-col v-if="FieldsHelper.isVisible('gender')" cols="12" sm="3">
            <p>{{$t('personalStep.gender') + FieldsHelper.isMandatoryLabel('gender')}}</p>
            <v-radio-group
            mandatory
            v-model="primaryProfile.gender"
            :rules="FieldsHelper.getRules('gender')"
            >
            <v-radio :label="$t('personalStep.male')" value="M"></v-radio>
            <v-radio :label="$t('personalStep.female')" value="F"></v-radio>
            </v-radio-group>
        </v-col>
        </v-row>
        <v-row no-gutters>
        <!-- Birth date ------>
        <v-col v-if="FieldsHelper.isVisible('birthdate')" cols="12" sm="2">
            <v-menu
            ref="menuBirthDate"
            v-model="menuBirthDate"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
            >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                v-model="computedBirthDate"
                :rules="FieldsHelper.getRules('birthdate')"
                :label="$t('personalStep.birthDate') + FieldsHelper.isMandatoryLabel('birthdate')"
                persistent-hint
                :hint="$t('commons.changeYearHint')"
                readonly
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
                autocomplete="off"
                ></v-text-field>
            </template>
            <v-date-picker
                v-model="primaryProfile.birthDate"
                no-title
                @input="menuBirthDate = false"
            ></v-date-picker>
            </v-menu>
        </v-col>
        <v-spacer></v-spacer>
        <!-- Birth Country ------>
        <v-col v-if="FieldsHelper.isVisible('birthcountry')" cols="12" md="2">
            <v-autocomplete
            :rules="FieldsHelper.getRules('birthcountry')"
            v-model="primaryProfile.birthCountry"
            :items="countryList"
            item-text="countryName"
            item-value="countryCode"
            :label="$t('personalStep.birthCountry') + FieldsHelper.isMandatoryLabel('birthcountry')"
            autocomplete="off"
            ></v-autocomplete>
        </v-col>
        <v-spacer></v-spacer>
        <!-- Birth city ------>
        <v-col v-if="FieldsHelper.isVisible('birthtown')" cols="12" md="2">
            <v-text-field
            v-model="primaryProfile.birthTown"
            :rules="FieldsHelper.getRules('birthtown')"
            :label="$t('personalStep.birthTown') + FieldsHelper.isMandatoryLabel('birthtown')"
            autocomplete="off"
            >Birth city</v-text-field
            >
        </v-col>
        <v-spacer></v-spacer>
        <!-- Personal code ------>
        <v-col v-if="FieldsHelper.isVisible('personalcode')" cols="12" md="3">
            <v-text-field
            v-model="primaryProfile.personalCode"
            :rules="FieldsHelper.getRules('personalcode')"
            :label="$t('personalStep.fiscalCode') + FieldsHelper.isMandatoryLabel('personalcode')"
            autocomplete="off"
            >Personal code</v-text-field
            >
        </v-col>
        </v-row>
    </v-container>
</template>

<script>
  import FieldsHelper from '../helper/FieldsHelper'
  export default {
    props: ["primaryProfile","countryList"],
    data: () => ({
      FieldsHelper,
      menuBirthDate: false,
    }),
    computed: {
      computedBirthDate() {
        return this.FieldsHelper.formatDate(this.primaryProfile.birthDate);
      },
    },
  }
</script>