<template>
  <div class="container">
    <navigation v-bind:logo="logo"/>
    <v-card elevation="2">
      <v-card-text>
        <v-row class="justify-center">
          <p class="text-center">{{$t('otpCheck.otpCheckConfirmIdentity')}}<br>
            {{$t('otpCheck.otpCheckByMailEnter')}}
            {{$t('otpCheck.otpCheckExpiration')}} <label class="font-weight-bold">{{$t('otpCheck.otpCheckExpirationRetry')}}</label></p>
        </v-row>
        <v-row class="justify-center">
          <v-otp-input
                inputClasses="otp-input"
                :numInputs="6"
                :isInputNum="true"
                separator="-"
                :shouldAutoFocus="true"
                @on-complete="handleOnComplete"
              />
        </v-row>
      </v-card-text>
    </v-card>
    <LiveChatWidget
        license="16676499"
        visibility="minimized"
        :sessionVariables=sessionVariables
        v-on:ready="onChatReady"
      />
  </div>
</template>

<script>
import Navigation from '../components/Navigation';
import { LiveChatWidget } from '@livechat/widget-vue/v2'
export default {
  name: "Login",
  components: {
    Navigation,
    LiveChatWidget
  },
  data(){
    return{
      logo:{
        local: true,
        logo: null
      },
      sessionVariables:{}
    }
  },
  mounted(){
    if (this.$route.query.token) {
      this.requestOtp(this.$route.query.token)
      this.sessionVariables =  {
        reservationNumber: this.$route.query.token
      }
    } else {
      this.$router.replace({name: "error404"})
    }
  },
  methods: {
    handleOnComplete(value) {
      this.submit(value)
    },
    submit(value){
      let req = new Object();
      req.token = this.$route.query.token;
      req.code = value;
      this.$store
        .dispatch("loadReservationByOtp", req).then(() => {
          this.$router.replace({name: "reservation"})
        }).catch(() => {
          this.$notify({group: 'alert',type: 'error',title: 'Error',text: "OTP code not found"})
        })
    },
    requestOtp(value){
      this.$store.dispatch("sendOtp", value)
    },
  }
}
</script>
<style>
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: "center";
}
.error {
  border: 1px solid red !important;
}
</style>

