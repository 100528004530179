import store from './../store'

class FieldHelper {
    isVisible(value) { 
      return store.state.facility.fieldsRules.find((f) => f.name === value).visible;
    }

    isStepVisible(value){
      return store.state.facility.steps.find((f) => f.name === value).visible;
    }

    isMandatory(value) {
      return store.state.facility.fieldsRules.find((f) => f.name === value).mandatory;
    }

    isMandatoryLabel(value) {
      return store.state.facility.fieldsRules.find((f) => f.name === value).mandatory?'*':'';
    }

    getRules(string) {
      let rule = [];
      if (store.state.facility.fieldsRules.find((f) => f.name === string).mandatory) {
          if(string == "docfront" || string == "docback"){
            rule = [(v) => (!!v && v.type === "image/jpeg") || "You must upload a valid picture"]
          }else{
            rule = [(value) => !!value || "This Field is required."];
          }
      }
      return rule;
    }

    formatDate(date) {
      if (!date) return null;
      var dt = new Date(date).toISOString().substr(0, 10);
      const [year, month, day] = dt.split("-");
      return `${day}/${month}/${year}`;
    }

    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    }

    clearObject(object) {
        delete object.invitationMailDate
        delete object.reminderMailDate
        delete object.submissionDate
        delete object.lastClick
        delete object.securityCodeExpire
        delete object.securityCode
        delete object.departureDate
        return object
    }
}

export default new FieldHelper();