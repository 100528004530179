<template>
  <div>
    <v-icon color="black">mdi-web</v-icon>
    <select v-model="$i18n.locale" @change="onChange()">
      <option v-for="lang in langs" :key="lang.code" :value="lang.code">
        {{ lang.description }}
      </option>
    </select>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: 'SelectLocale',
  data() {
    return { 
        langs:[
            {code:"en",description:"English"},
            {code:"de",description:"German"},
            {code:"it",description:"Italian"}
        ] 
    }
  },
  computed: {
    ...mapGetters(["facilityConfig"]),
  },
  methods:{
    onChange() {
      if(this.facilityConfig){
        let code = new Object();
        code.code = this.facilityConfig.facilityCode
        code.language = this.$i18n.locale
        this.$store.dispatch("loadReservationPrivacy", code)
      }
      console.log(this.facilityConfig.facilityCode)
      console.log(this.$i18n.locale);
    },
  }
}
</script>