<template>
    <v-container class="mb-12">
            <v-row no-gutters>
            <v-col v-if="FieldsHelper.isVisible('firstemail')" cols="12" md="3">
                <v-text-field
                :rules="emailRules"
                v-model="primaryProfile.primaryEmail"
                :label="$t('contactStep.firstEmail') + FieldsHelper.isMandatoryLabel('firstemail')"
                autocomplete="off"
                required
                ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <v-col v-if="FieldsHelper.isVisible('secondemail')" cols="12" md="3">
                <v-text-field
                v-model="primaryProfile.secondaryEmail"
                :label="$t('contactStep.secondEmail') + FieldsHelper.isMandatoryLabel('secondemail')"
                autocomplete="off"
                ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <v-col v-if="FieldsHelper.isVisible('phone')" cols="12" md="4">
                <vue-tel-input-vuetify
                :mode="international"
                :valid-characters-only="true"
                :disabledFetchingCountry="false"
                :rules="FieldsHelper.getRules('phone')"
                @input="onInput"
                :label="$t('contactStep.phoneNumber') + FieldsHelper.isMandatoryLabel('phone')"
                autocomplete="off"
                v-model="primaryProfile.phoneNumber">
                </vue-tel-input-vuetify>
            </v-col>
            </v-row>
    </v-container>
</template>

<script>
  import FieldsHelper from '../helper/FieldsHelper'
  export default {
    props: ["primaryProfile"],
    mounted(){
      if (this.primaryProfile.nationality) {
        this.phoneCountry = this.primaryProfile.nationality
      } else {
        this.phoneCountry = 'IT'
      }
    },
    data() {
      return {
        phoneCountry:'',
        phone: {
          number: '',
          valid: false,
          country: undefined,
        },
        FieldsHelper,
      }
    },
    computed: {
      emailRules() {
        return [
          (v) => !!v || this.$t('contactStep.requiredEmail'),
          (v) => /.+@.+\..+/.test(v) || this.$t('contactStep.notValidEmail'),
        ]
      },
    },
    methods: {
      onInput(formattedNumber, { number, valid, country }) {
        this.primaryProfile.phoneNumber = number.e164;
        this.phone.valid = valid;
        this.phone.country = country && country.name;
      },
    }
  }
</script>