<template>
    <v-container class="mb-12">
        <v-row>
            <v-col v-if="FieldsHelper.isVisible('brand')" cols="12" md="2">
            <v-text-field
                v-model="primaryProfile.carBrand"
                :rules="FieldsHelper.getRules('brand')"
                :label="$t('carStep.brand') + FieldsHelper.isMandatoryLabel('brand')"
                autocomplete="off"
            ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <v-col v-if="FieldsHelper.isVisible('model')" cols="12" md="2">
            <v-text-field
                v-model="primaryProfile.carModel"
                :rules="FieldsHelper.getRules('model')"
                :label="$t('carStep.model') + FieldsHelper.isMandatoryLabel('model')"
                autocomplete="off"
            ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <v-col v-if="FieldsHelper.isVisible('type')" cols="12" md="2">
            <v-select
                v-model="primaryProfile.carType"
                :rules="FieldsHelper.getRules('type')"
                :items="cartype"
                :label="$t('carStep.type') + FieldsHelper.isMandatoryLabel('type')"
                autocomplete="off"
            ></v-select>
            </v-col>
            <v-spacer></v-spacer>
            <v-col v-if="FieldsHelper.isVisible('plate')" cols="12" md="3">
            <v-text-field
                v-model="primaryProfile.carPlate"
                @keydown="plateCheck"
                :rules="FieldsHelper.getRules('plate')"
                :label="$t('carStep.plate') + FieldsHelper.isMandatoryLabel('plate')"
                autocomplete="off"
            ></v-text-field>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
  import FieldsHelper from '../helper/FieldsHelper'
  export default {
    props: ["primaryProfile"],
    data: () => ({
      FieldsHelper,
      cartype: ["Berlina", "Station Wagon", "SUV", "City car"],
    }),
    methods:{
        plateCheck(e) {
          if (/^\W$/.test(e.key)) {
            e.preventDefault();
          }
        },
    }
  }
</script>