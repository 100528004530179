<template>
    <v-container class="mb-12">
        <v-row>
            <v-col v-if="FieldsHelper.isVisible('docfront')" cols="12" sm="12" md="6">
               <v-file-input
                name="docfront"
                :rules="docFrontRules"
                accept="image/jpeg"
                :label="$t('documentStep.docFront') + FieldsHelper.isMandatoryLabel('docfront')"
                v-model="primaryProfile.documentFrontFile"
                @change="frontDoc"
            ></v-file-input>
            </v-col>
            <v-col v-if="FieldsHelper.isVisible('docback')" cols="12" sm="12" md="6">
            <v-file-input
                name="docback"
                :rules="docBackRules"
                accept="image/jpeg"
                :label="$t('documentStep.docBack') + FieldsHelper.isMandatoryLabel('docback')"
                v-model="primaryProfile.documentBackFile"
                @change="backDoc"
            ></v-file-input>
            </v-col>
        </v-row>
        <v-row>
            <v-col v-if="FieldsHelper.isVisible('documenttype')" cols="12" sm="12" md="6">
            <v-select
                v-model="primaryProfile.documentType"
                :rules="FieldsHelper.getRules('documenttype')"
                :items="doctypes"
                :label="$t('documentStep.documentType') + FieldsHelper.isMandatoryLabel('documenttype')"
            ></v-select>
            </v-col>
            <v-col
            v-if="FieldsHelper.isVisible('documentnumber')"
            cols="12"
            sm="12"
            md="6"
            >
            <v-text-field
                v-model="primaryProfile.documentNumber"
                :rules="FieldsHelper.getRules('documentnumber')"
                required
                :label="$t('documentStep.documentNumber') + FieldsHelper.isMandatoryLabel('documentnumber')"
                autocomplete="off"
                >Document Number</v-text-field
            >
            </v-col>
        </v-row>
        <v-row>
            <v-col v-if="FieldsHelper.isVisible('issuedate')" cols="12" sm="12" md="4">
            <v-menu
                ref="menuIssueDate"
                v-model="menuIssueDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="computedIssueDate"
                    :label="$t('documentStep.issueDate') + FieldsHelper.isMandatoryLabel('issuedate')"
                    :rules="FieldsHelper.getRules('issuedate')"
                    :hint="$t('commons.changeYearHint')"
                    persistent-hint
                    readonly
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    autocomplete="off"
                ></v-text-field>
                </template>
                <v-date-picker
                v-model="primaryProfile.documentIssueDate"
                no-title
                @input="menuIssueDate = false"
                ></v-date-picker>
            </v-menu>
            </v-col>
            <v-col v-if="FieldsHelper.isVisible('expdate')" cols="12" sm="12" md="4">
            <v-menu
                ref="menuExpDate"
                v-model="menuExpDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="computedExpDate"
                    :label="$t('documentStep.expDate') + FieldsHelper.isMandatoryLabel('expdate')"
                    readonly
                    :rules="FieldsHelper.getRules('expdate')"
                    :hint="$t('commons.changeYearHint')"
                    persistent-hint
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    autocomplete="off"
                ></v-text-field>
                </template>
                <v-date-picker
                v-model="primaryProfile.documentExpirationDate"
                no-title
                @input="menuExpDate = false"
                ></v-date-picker>
            </v-menu>
            </v-col>
            <v-col v-if="FieldsHelper.isVisible('issuelocation')" cols="12" md="4">
            <v-text-field
                :rules="FieldsHelper.getRules('issuelocation')"
                v-model="primaryProfile.documentIssueLocation"
                :label="$t('documentStep.issuedBy') + FieldsHelper.isMandatoryLabel('issuelocation')"
                autocomplete="off"
                >Issued by</v-text-field
            >
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
  import FieldsHelper from '../helper/FieldsHelper'
  import imageCompression from 'browser-image-compression';
  export default {
    props: ["primaryProfile"],
    data: () => ({
      compressOptions: {
          maxWidthOrHeight: 1024,
      },
      FieldsHelper,
      menuExpDate: false,
      menuIssueDate: false,
      doctypes: ["Driver License", "ID Card", "Passport"],
    }),
    methods:{
      frontDoc(file) {
        if(file){  
          imageCompression(file, this.compressOptions)
            .then((compressedFile) => {
                compressedFile.name = file.name
                this.primaryProfile.documentFrontFile = new File([compressedFile], file.name, { lastModified: new Date().getTime(), type: compressedFile.type });
            })
            .catch((error) => {
                console.log(error.message);
            });
        }
      },
      backDoc(file) {
        if(file){
          imageCompression(file, this.compressOptions)
              .then((compressedFile) => {
                  this.primaryProfile.documentBackFile = new File([compressedFile], file.name, { lastModified: new Date().getTime(), type: compressedFile.type })
              })
              .catch((error) => {
                  console.log(error.message);
              });
        }
      },
    },
    computed: {
      tempFrontDoc(){
        return this.primaryProfile.documentFrontFile;
      },
      computedExpDate() {
        return this.FieldsHelper.formatDate(this.primaryProfile.documentExpirationDate);
      },
      docFrontRules(){
        return [v => !v || v.type === "image/jpeg" || this.$t('documentStep.validPictureError')]
      },
      docBackRules(){
        return [v => !v || v.type === "image/jpeg" || this.$t('documentStep.validPictureError')]
      },
      computedIssueDate() {
        return this.FieldsHelper.formatDate(this.primaryProfile.documentIssueDate);
      },
    },
  }
</script>