<template>
    <v-container class="mb-12">
        <v-row no-gutters>
        <!-- Residence country ------>
        <v-col v-if="FieldsHelper.isVisible('rescountry')" cols="12" md="5">
            <v-autocomplete
            :rules="FieldsHelper.getRules('rescountry')"
            v-model="primaryProfile.residenceCountry"
            :items="countryList"
            item-text="countryName"
            item-value="countryCode"
            :label="$t('residenceStep.country') + FieldsHelper.isMandatoryLabel('rescountry')"
            autocomplete="off"
            ></v-autocomplete>
        </v-col>
        <v-spacer></v-spacer>
        <!-- Residence city ------>
        <v-col v-if="FieldsHelper.isVisible('restown')" cols="12" md="6">
            <v-text-field
            v-model="primaryProfile.residenceTown"
            :rules="FieldsHelper.getRules('restown')"
            :label="$t('residenceStep.town') + FieldsHelper.isMandatoryLabel('restown')"
            autocomplete="off"
            >Residence city</v-text-field
            >
        </v-col>
        </v-row>
        <v-row no-gutters>
        <v-col v-if="FieldsHelper.isVisible('resaddress')" cols="12" md="8">
            <v-text-field
            v-model="primaryProfile.residenceAddress"
            :rules="FieldsHelper.getRules('resaddress')"
            :label="$t('residenceStep.address') + FieldsHelper.isMandatoryLabel('resaddress')"
            autocomplete="off"
            >Residence address
            </v-text-field>
        </v-col>
        <v-spacer></v-spacer>
        <v-col v-if="FieldsHelper.isVisible('postalcode')" cols="12" md="3">
            <v-text-field
            v-model="primaryProfile.residencePostalCode"
            :rules="FieldsHelper.getRules('postalcode')"
            :label="$t('residenceStep.postalCode') + FieldsHelper.isMandatoryLabel('postalcode')"
            autocomplete="off"
            >ZIP code</v-text-field
            >
        </v-col>
        </v-row>
    </v-container>
</template>

<script>
  import FieldsHelper from '../helper/FieldsHelper'
  export default {
    props: ["primaryProfile","countryList"],
    data: () => ({
      checkbox: false,
      FieldsHelper,
    })
  }
</script>