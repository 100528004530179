<template>
  <div class="container">
      <navigation v-bind:logo="logo"/>
      <v-card elevation="3" style="text-align: center;"> <br/>
      <br/><h1>404 Not found!</h1> <br/>
      <br/></v-card>
      <!-- <LiveChatWidget
        license="16676499"
        visibility="minimized"
        :sessionVariables=sessionVariables
        v-on:ready="onChatReady"
      /> -->
  </div>
</template>
<script>
import Navigation from '../../components/Navigation';
// import { LiveChatWidget } from '@livechat/widget-vue/v2'
export default {
  name: "error404",
  components: {
    Navigation,
    // LiveChatWidget,
  },
  data(){
    return{
      logo:{
        local: true,
        logo: null
      },
      sessionVariables:{
        reservationNumber: '123456'
      }
    }
  },
  // mounted(){
  //  this.loadChat()
  // },
  // methods:{
  //   loadChat(){
  //     this.LiveChatWidget
  //   },
  //   onChatReady(event) {
  //     console.log('LiveChatWidget.onNewEvent', event)
  //   },
  // }
}
</script>