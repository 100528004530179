/* eslint-disable no-unused-vars */
import Vue from 'vue'
import AuthService from '../../../service/auth.service';

const state = {}

// getters
const getters = {}

const actions = {
    getAuthToken(context){
        return AuthService.getAuth().then(
            data => {
              return Promise.resolve(data);
            },
            error => {
            context.commit('showMessage',{type:'error',message:error.response.data.message})     
              return Promise.reject(error);
            }
        );
    }   
}


// mutations
const mutations = {
    showMessage(state, data) {
        Vue.notify({
            group: 'alert',
            type: data.type,
            text: data.message
        });
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}