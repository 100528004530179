<template>
    <v-container class="mb-12">
        <accompany-item v-bind:accompanyList="accompanyList" :maxGuests="maxGuests"></accompany-item>
    </v-container>
</template>
<script>
import AccompanyItem from "../components/Accompany";
export default {
    props: ["accompanyList","maxGuests"],
    data: () => ({

    }),
    components: {
      AccompanyItem,
  },
}
</script>