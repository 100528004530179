/* eslint-disable no-unused-vars */
import Vue from 'vue'
import BackendService from '../../../service/backend.service';
import converter from '../../../helper/converter';
import FieldsHelper from '../../../helper/FieldsHelper';
import router from '../../../router';

const state = {
    loadedReservation: null,
    reservation: null,
    accompanies:[],
    privacy:[],
    privacies:[]
}

// getters
const getters = {
    loadedReservation: state => {
      return state.loadedReservation;
    },
    reservation: state => {
        return state.reservartion;
    },
    accompanies: state => {
        return state.accompanies;
    },
    privacies: state => {
      return state.privacies;
    },
}

const actions = {
    sendOtp(context,code){
        return BackendService.get('/reservation/findReservationByToken/' + code,true).then(
            data => {
              return Promise.resolve(data.data);
            },
            error => {
              context.commit('showMessage',{type:'error',message:error.response.data.message})     
              return Promise.reject(error);
            }
        );
    },
    loadDocument(context,url){
        return BackendService.get(url,true,true,'blob').then(
            data => {
              console.log("********** DOCUMENT **********") 
              //const downloadUrl = window.URL.createObjectURL();
              return Promise.resolve(data.data);
            },
            error => {
              context.commit('showMessage',{type:'error',message:"Can't retrieve document"})     
              return Promise.reject(error);
            }
        );
    },
    loadReservationByOtp(context,data){
        return BackendService.get('/reservation/findReservationByTokenAndSecurityCode/' + data.token + '/' + data.code,true).then(
            data => {
              console.log(data.data.reservationProfileList)
              let result = []
              data.data.reservationProfileList.map((obj) => {
                if (obj.primaryProfile === false){
                    result.push(obj)
                }
              });
              context.dispatch('addAccompanies', result);
              context.dispatch('loadFacilityConfig', data.data.facilityCode);
              context.commit('setReservation', data.data);
              return Promise.resolve(data.data);
            },
            error => {
              context.commit('showMessage',{type:'error',message:error.response.data.message})   
              return Promise.reject(error);
            }
        );
    },
    addNewReservation(context,reservationdata){
      const formData = new FormData();
      reservationdata = FieldsHelper.clearObject(reservationdata)
      converter.buildFormData(formData, reservationdata);
      var contentType = "multipart/form-data"
      context.commit('goToSuccess')
      return BackendService.save('/reservation/add',true,formData,contentType).then(
          data => {
            context.commit('goToSuccess')
            return Promise.resolve(data.data);
          },
          error => {
          context.commit('showMessage',{type:'error',message:error.response.data.message})     
            return Promise.reject(error);
          }
      );
    },
    updateReservation(context,reservationdata,){
      const formData = new FormData();
      reservationdata = FieldsHelper.clearObject(reservationdata)
      converter.buildFormData(formData, reservationdata);
      var contentType = "multipart/form-data"
      return BackendService.update('/reservation/save',true,formData,contentType).then(
          data => {
            if(data.data.transaction){
              context.commit('goToTransaction', data.data.transaction)
            }else{
              context.commit('goToSuccess')
            }
            return Promise.resolve(data.data);
          },
          error => {
          context.commit('showMessage',{type:'error',message:error.response.data.message})     
            return Promise.reject(error);
          }
      );
    },
    addAccompanies(context,data){
        context.commit('setAccompanies', data);
    },
    verifyTransaction(context,transaction){
      return BackendService.save('/reservation/transaction/verify',true,transaction,'application/json').then(
          data => {
            context.commit('goToSuccess')
            return Promise.resolve(data.data);
          },
          error => {
          context.commit('showMessage',{type:'error',message:error.response.data.message})     
            return Promise.reject(error);
          }
      );
    },
    sendNewOtpTransaction(context,transaction){
      return BackendService.save('/reservation/transaction/resendOtp',true,transaction,'application/json').then(
          data => {
            return Promise.resolve(data.data);
          },
          error => {
            context.commit('showMessage',{type:'error',message:error.response.data.message})     
            return Promise.reject(error);
          }
      );
    },
    loadReservationPrivacy({commit, state},code){
      return BackendService.get('/facility/facilityPrivacy/facilityCode/' + code.code + "/" + code.language,true).then(
          data => {
            let privacyList = [];
            data.data.map(function(value){
              let priv = {}
              priv.privacyType = value.type;
              priv.content = value.content;
              priv.mandatory = value.mandatory
              if (state.loadedReservation && state.loadedReservation.reservationPrivacyList){
                if (state.loadedReservation.reservationPrivacyList.lenght < 1){
                  priv.accepted = state.loadedReservation.reservationPrivacyList.find((f) => f.privacyType === value.type).accepted?true:false;
                }else{
                  priv.accepted = false
                }
              }
              privacyList.push(priv);
            });
            // this.privacies = privacyList;
            commit('setReservationPrivacy', privacyList);
            return Promise.resolve(data.data);
          },
          error => {
          commit('showMessage',{type:'error',message:error.response.data.message})     
            return Promise.reject(error);
          }
      );
  },     
}


// mutations
const mutations = {
    setReservation(state,data) {
      state.loadedReservation = data
      state.reservation = data
      state.privacyList = data.reservationPrivacyList
    },
    setAccompanies(state, data){
      state.accompanies = data;
    },
    goToSuccess(state,data){
      router.push({name: "success"})
    },
    goToTransaction(state,data){
      router.push({name: "transaction", params: { transactionId: data.transactionId}})
      //this.$router.replace({name: "Transaction",query: {token: this.$route.query.token, otp: value}})
    },
    setReservationPrivacy(state,data) {
      state.privacies = data
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}