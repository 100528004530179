<template>
  <v-data-table
    v-if="accompanyList != null"
    :headers="translatedHeader"
    :items="accompanyList"
    :hide-default-footer="true"
    :must-sort="false"
    :light="true"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>{{$t('commons.accompany')}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="800px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="!maxGuestReached()" color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              {{$t('commons.newAccompany')}}
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
          <v-form v-model="accompanyDialog.valid" ref="form2">
            <v-card-text>
              <v-container>
                <v-row no-gutters>
                  <v-col cols="12" md="5">
                    <v-file-input v-if="FieldsHelper.isVisible('accompanydocfront')"
                      v-model="editedItem.documentFrontFile"
                      name="accompanydocfront"
                      :rules="FieldsHelper.getRules('accompanydocfront')"
                      accept="image/jpeg"
                      :label="$t('documentStep.docFront') + FieldsHelper.isMandatoryLabel('accompanydocfront')"
                      @change="frontDoc"
                    ></v-file-input>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="12" md="5">
                    <v-file-input v-if="FieldsHelper.isVisible('accompanydocback')"
                      v-model="editedItem.documentBackFile"
                      name="accompanydocback"
                      :rules="FieldsHelper.getRules('accompanydocback')"
                      accept="image/jpeg"
                      :label="$t('documentStep.docBack') + FieldsHelper.isMandatoryLabel('accompanydocback')"
                      @change="backDoc"
                    ></v-file-input>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      :rules="getRules('accompanyname')"
                      v-model="editedItem.firstName"
                      autocomplete="off"
                      :label="$t('personalStep.firstName') + FieldsHelper.isMandatoryLabel('accompanyname')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                     :rules="getRules('accompanylastname')"
                      v-model="editedItem.lastName"
                      :label="$t('personalStep.lastName') + FieldsHelper.isMandatoryLabel('accompanylastname')"
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                    <!-- BIRTHDAY PICKER-->
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="computedAccompanyBirthDate"
                          :label="$t('personalStep.birthDate') + FieldsHelper.isMandatoryLabel('accompanybirthdate')"
                          :rules="FieldsHelper.getRules('accompanybirthdate')"
                          :hint="$t('commons.changeYearHint')"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          autocomplete="off"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editedItem.birthDate"
                        :rules="getRules('accompanybirthdate')"
                        no-title
                        @input="menu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6">
                  <v-autocomplete
                    :rules="getRules('accompanybirthnation')"
                    v-model="editedItem.birthCountry"
                    :items="location.countries"
                    item-text="countryName"
										item-value="countryCode"
                    :label="$t('personalStep.birthCountry') + FieldsHelper.isMandatoryLabel('accompanybirthnation')"
                    autocomplete="off"
                  ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      :rules="getRules('accompanybirthtown')"
                      v-model="editedItem.birthTown"
                      :label="$t('personalStep.birthTown') + FieldsHelper.isMandatoryLabel('accompanybirthtown')"
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <p>{{$t('personalStep.gender') +'*'}}</p>
                    <v-radio-group row mandatory v-model="editedItem.gender">
                      <v-radio :label="$t('personalStep.male')" value="M"></v-radio>
                      <v-radio :label="$t('personalStep.female')" value="F"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-autocomplete
                    :rules="getRules('accompanycitizenship')"
                    v-model="editedItem.nationality"
                    :items="location.countries"
                    item-text="countryName"
										item-value="countryCode"
                    :label="$t('personalStep.citizenship') + FieldsHelper.isMandatoryLabel('accompanycitizenship')"
                    autocomplete="off"
                  ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-autocomplete
                    :rules="getRules('accompanyresidencecountry')"
                    v-model="editedItem.residenceCountry"
                    :items="location.countries"
                    item-text="countryName"
										item-value="countryCode"
                    :label="$t('residenceStep.country') + FieldsHelper.isMandatoryLabel('accompanyresidencecountry')"
                    autocomplete="off"
                  ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      :rules="getRules('accompanyresidencetown')"
                      v-model="editedItem.residenceTown"
                      :label="$t('residenceStep.town') + FieldsHelper.isMandatoryLabel('accompanyresidencetown')"
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      :rules="getRules('accompanyresidenceaddress')"
                      v-model="editedItem.residenceAddress"
                      :label="$t('residenceStep.address') + FieldsHelper.isMandatoryLabel('accompanyresidenceaddress')"
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                {{$t('commons.cancel')}}
              </v-btn>
              <v-btn color="blue darken-1" text @click="save">
                {{$t('commons.save')}}
              </v-btn>
            </v-card-actions>
          </v-form>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline"
              >{{$t('commons.deleteItemQuestion')}}</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeConfirmDelete"
                >{{$t('commons.cancel')}}</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >{{$t('commons.ok')}}</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon small @click="deleteItem(item)">
        mdi-delete
      </v-icon>
     
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";
import FieldsHelper from '../helper/FieldsHelper'
import imageCompression from 'browser-image-compression';

export default {
  props:["accompanyList","maxGuests"],
   data() {
    return {
      compressOptions: {
        maxWidthOrHeight: 1024,
      },
      FieldsHelper,
      accompanyDialog: {
        valid: true,
        requiredRules: v => !!v || "Required field",
      },
      accompanyDocFrontFileObj:null,
      accompanyDocBackFileObj:null,
      dialog:false,
      date: null,
      menu: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {},
    }
  },
  computed: {
    ...mapGetters(["facilityConfig","location"]),
    formTitle() {
      return this.editedIndex === -1 ? this.$t('commons.newAccompany') : this.$t('commons.editAccompany');
    },
    validPictureError(){
      return this.$t('personalStep.validPictureError')
    },
    translatedHeader(){
      return [
        { text: this.$t('personalStep.firstName'),align: "start",value: "firstName",},
        { text: this.$t('personalStep.lastName'), value: "lastName", sortable: false },
        { text: this.$t('personalStep.birthCountry'), value: "birthCountry", sortable: false},
        { text: this.$t('personalStep.birthTown'), value: "birthTown", sortable: false },
        { text: this.$t('personalStep.gender'), value: "gender", sortable: false },
        { text: this.$t('personalStep.citizenship'), value: "nationality", sortable: false },
        { text: this.$t('residenceStep.country'), value: "residenceCountry", sortable: false },
        { text: this.$t('residenceStep.town'), value: "residenceTown", sortable: false },
        { text: this.$t('residenceStep.address'), value: "residenceAddress", sortable: false },
        { text: this.$t('commons.actions'), value: "actions", sortable: false },
      ]
    },
    computedAccompanyBirthDate() {
      return this.FieldsHelper.formatDate(this.editedItem.birthDate);
    },
  },
  created() {
  },
  methods: {
    frontDoc(file) {
     if(file){  
        imageCompression(file, this.compressOptions)
          .then((compressedFile) => {
              compressedFile.name = file.name
              this.editedItem.documentFrontFile = new File([compressedFile], file.name, { lastModified: new Date().getTime(), type: compressedFile.type });
          })
          .catch((error) => {
              console.log(error.message);
          });
      }
    },
    backDoc(file) {
      if(file){  
        imageCompression(file, this.compressOptions)
          .then((compressedFile) => {
              compressedFile.name = file.name
              this.editedItem.documentBackFile = new File([compressedFile], file.name, { lastModified: new Date().getTime(), type: compressedFile.type });
          })
          .catch((error) => {
              console.log(error.message);
          });
      }
    },
    editItem(item) {
        this.editedIndex = this.accompanyList.indexOf(item);
        this.editedItem = Object.assign({}, item)
        this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.accompanyList.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.accompanyList.splice(this.editedIndex, 1);
      this.$store.dispatch("addAccompanies", this.accompanyList)
      this.closeConfirmDelete();
    },
    getRules(string) {
      let rule = [];
      let fieldsRules = JSON.parse(this.facilityConfig.webCheckinConfiguration.fields);
      if (fieldsRules.find((f) => f.name === string).mandatory) {
          if(string == "accompanydocfront" || string == "accompanydocback"){
            rule = [v => !v || v.type === "image/jpeg" || this.validPictureError]
          }else{
            rule = [(value) => !!value || this.$t('commons.requiredField')];
          }
      }
      return rule;
    },
    maxGuestReached(){
      if(this.maxGuests -1 <= this.accompanyList.length){
        return true;
      }else{
        return false;
      }
    },
    close() {
      this.dialog = false;
      this.$refs.form2.reset()
      this.editedItem = {}
      this.$nextTick(() => {
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$refs.form2.reset()
      this.$nextTick(() => {
        this.editedIndex = -1;
      });
    },
    closeConfirmDelete(){
      this.dialogDelete = false;
    },
    save() {
      if(this.$refs.form2.validate()){
        if (this.editedIndex > -1) {
          Object.assign(this.accompanyList[this.editedIndex], this.editedItem);
        } else {
          this.accompanyList.push(this.editedItem);
          this.$store.dispatch("addAccompanies", this.accompanyList)
        }
        //this.$refs.form2.reset()
        this.editedItem = {}
        this.close();
      }
    },
  },
};
</script>
