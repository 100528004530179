 export default {
  getFullAuthHeader() {
    let token = localStorage.getItem('token');
    if (token != null) {
      return { Authorization: 'Bearer ' + token };
    } else {
      return {
        'Content-Type':'application/x-www-form-urlencoded', 
        'Authorization': 'Basic ' + btoa(process.env.VUE_APP_CLIENT_ID +':'+ process.env.VUE_APP_CLIENT_SECRET)
      };
    }
  },
  getToken() {
    let token = localStorage.getItem('token');
    if (token != null) {
      return 'Bearer ' + token ;
    }
  }
}
