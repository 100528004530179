<template>
  <div class="container">
    <loading :active.sync="awaitResponse" 
              :is-full-page="fullPage"/>
    <navigation v-if="logo" v-bind:logo="'data:image/jpeg;base64,' + logo"/>
      <v-alert
      v-if="!isLoading && !partial"
      dense
      border="left"
      type="warning"
      dismissible
    > {{$t('reservation.infoCompleteCheckin')}}
      </v-alert>
    <v-container>
      <form-wizard v-if="!isLoading" title="" subtitle="" stepSize="xs" color="#D9B767" @on-complete="saveForm" >
        <tab-content v-if="FieldsHelper.isStepVisible('reservationdata')" :title="$t('reservation.reservation')" :before-change="()=>validateStep('reservationData')">
          <v-form ref="reservationData">
            <reservation-data :reservation="this.activeReservation" ></reservation-data>
          </v-form>
        </tab-content>
        <tab-content v-if="FieldsHelper.isStepVisible('documentdata')" :title="$t('reservation.document')" :before-change="()=>validateStep('documentData')">
          <v-form ref="documentData">
            <document-data :primaryProfile="this.primaryProfile" ></document-data>
          </v-form>
        </tab-content>
        <tab-content v-if="FieldsHelper.isStepVisible('personaldata')" :title="$t('reservation.personal')" :before-change="()=>validateStep('personalData')">
          <v-form ref="personalData">
            <personal-data :primaryProfile="this.primaryProfile" :countryList="this.countryList" ></personal-data>
          </v-form>
        </tab-content>
        <tab-content v-if="FieldsHelper.isStepVisible('residencedata')" :title="$t('reservation.residence')" :before-change="()=>validateStep('residenceData')">
          <v-form ref="residenceData">
            <residence-data :primaryProfile="this.primaryProfile" :countryList="this.countryList" ></residence-data>
          </v-form>
        </tab-content>
        <tab-content v-if="FieldsHelper.isStepVisible('contactdata')" :title="$t('reservation.contact')" :before-change="()=>validateStep('contactData')">
          <v-form ref="contactData">  
            <contact-data :primaryProfile="this.primaryProfile"></contact-data>
          </v-form>
        </tab-content>
        <tab-content v-if="FieldsHelper.isStepVisible('cardata')" :title="$t('reservation.car')" :before-change="()=>validateStep('carData')">
          <v-form ref="carData">
            <car-data :primaryProfile="this.primaryProfile"></car-data>
          </v-form>
        </tab-content>
        <tab-content v-if="FieldsHelper.isStepVisible('privacydata')" :title="$t('reservation.privacy')" :before-change="()=>validateStep('privacyData')">
          <v-form ref="privacyData">
            <privacy-data :privacies="this.privacies"></privacy-data>
          </v-form>
        </tab-content>
        <tab-content v-if="FieldsHelper.isStepVisible('accompanydata')" :title="$t('reservation.accompany')">
          <accompany-data :accompanyList="this.accompanyList" :maxGuests="maxGuests"></accompany-data>
        </tab-content>
      </form-wizard>    
    </v-container>
    <LiveChatWidget
        license="16676499"
        visibility="minimized"
        :sessionVariables=sessionVariables
        :customerName=this.fullName
        :customerEmail=this.primaryProfile.primaryEmail
        v-on:ready="onChatReady"
      />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { LiveChatWidget } from '@livechat/widget-vue/v2'
import Navigation from '../components/Navigation';
import ReservationData from '../steps/ReservationData';
import DocumentData from '../steps/DocumentData';
import PersonalData from '../steps/PersonalData';
import ResidenceData from '../steps/ResidenceData';
import ContactData from '../steps/ContactData';
import CarData from '../steps/CarData';
import PrivacyData from '../steps/PrivacyData';
import AccompanyData from '../steps/AccompanyData';
import {FormWizard, TabContent} from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import FieldsHelper from '../helper/FieldsHelper'

export default {
  name: "reservation",
  mounted() {
    if(!this.$route.query.idHotel){
      if (this.loadedReservation) {
        this.loadReservation(this.loadedReservation)
      } else {
        this.$router.push({name: "error404"})
      }
    }else{
      this.loadConfig(this.$route.query.idHotel);
    }
  },
  components: {
    ReservationData,
    DocumentData,
    PersonalData,
    ResidenceData,
    ContactData,
    CarData,
    PrivacyData,
    AccompanyData,
    FormWizard,
    TabContent,
    Navigation,
    Loading,
    LiveChatWidget
  },
  computed: {
    ...mapGetters(["accompanies","loadedReservation","privacies"]),
  },
  data() {
    return {
      FieldsHelper,
      docFrontFileObj:null,
      docBackFileObj:null,
      fullPage:true,
      awaitResponse: false,
      isLoading: true,
      countryList: [],
      fullName:'',
      activeReservation: {
        fromWebCheckin: true,
        housed: false
      },
      primaryProfile: {
        primaryProfile: true,
      },
      logo: null,
      steps: null,
      fieldsRules: null,
      minyear: "1980",
      valid: true,
      dialogaccompany: false,
      e1: 1,
      docfront: null,
      docback: null,
      modal2: false,
      doctype: "",
      accompanyList: [],
      partial: false,
      maxGuests:0,
      emptyCheckin:false,
      sessionVariables:{}
    };
  },
  methods: {
    onChange(event) {
      this.data = event.data;
    },
    logEvent(key, $event) {
      console.log('vjsf event', key, $event)
    },
    validateStep(name) {
      var refToValidate = this.$refs[name];
      return refToValidate.validate();
    },
    async loadReservation(res) {
          this.activeReservation = JSON.parse(JSON.stringify(res));
          this.maxGuests = res.guests;
          var resultPrimary = null;
          var accompanies = [];
          this.activeReservation.reservationProfileList.forEach((element)=>{
              if(element.documentFrontUrl){
                this.$store.dispatch("loadDocument", element.documentFrontUrl).then((res) => { 
                  var blob = new Blob([res]);
                  element.documentFrontFile = new File([blob], element.documentFrontName, {type: res.type});
                })
              } 
              if(element.documentBackUrl){
                this.$store.dispatch("loadDocument", element.documentBackUrl).then((res) => { 
                  var blob = new Blob([res]);
                  element.documentBackFile = new File([blob], element.documentBackName, {type: res.type});
                })
              }
              if(element.primaryProfile == true){
                resultPrimary = element
              }else{
                accompanies.push(element)
              }
          });
          if(resultPrimary != null){
            this.primaryProfile = resultPrimary
            this.fullName = this.primaryProfile.firstName + ' ' + this.primaryProfile.lastName
          }
          this.accompanyList = accompanies;
          this.activeReservation.arrivalDate = new Date(this.activeReservation.arrivalDate)
            .toISOString()
            .substr(0, 10);
          this.arrivalDate = this.FieldsHelper.formatDate(this.activeReservation.arrivalDate);
          this.loadConfig(this.activeReservation.facilityCode)
          this.sessionVariables =  {
            reservationNumber: this.loadedReservation.reservationNumber
          }
    },
    async loadConfig(idHotel) {
      this.$store.dispatch("loadFacilityConfig", idHotel).then((res) =>{
        if(!res.webCheckinConfiguration.emptyCheckin){
        this.steps = JSON.parse(res.webCheckinConfiguration.steps);
        this.fieldsRules = JSON.parse(res.webCheckinConfiguration.fields);
        this.logo = res.facilityLogo;
        this.partial = res.webCheckinConfiguration.partialData;
        this.emptyCheckin = res.webCheckinConfiguration.emptyCheckin;
        this.isLoading = false
        this.loadPrivacy(idHotel);
        }else{
          this.$router.replace({name: "error404"})
        }
      })
      this.loadForms(idHotel);
    },
    async loadForms(idHotel) {
      let obj = new Object()
      obj.code = idHotel;
      obj.language = this.$i18n.locale
      this.$store.dispatch("loadAdditionalForms", obj)
    },
    async loadCountries() {
      await this.$store.dispatch("loadCountries").then(res => this.countryList = JSON.parse(JSON.stringify(res)));
    },
    async loadPrivacy(idHotel) {
      console.log(this.$i18n.locale)
      let obj = new Object()
      obj.code = idHotel;
      obj.language = this.$i18n.locale
      this.$store.dispatch("loadReservationPrivacy", obj)
      this.loadCountries();
    },
    saveForm(){
      this.activeReservation.reservationProfileList = [];
      var acc = []
      this.accompanyList.forEach(function(element){
        element.isCheckedIn = false
        element.housed = false
        acc.push(element)
      });
      this.primaryProfile.isCheckedIn = false
      this.primaryProfile.housed = false
      acc.push(this.primaryProfile)
      this.activeReservation.reservationProfileList = acc;
      this.activeReservation.reservationPrivacyList = this.privacies;
      if(!this.partial){
        if(this.maxGuests > this.activeReservation.reservationProfileList.length){
          var remaing = this.maxGuests - this.activeReservation.reservationProfileList.length + ' ' + this.$t('reservation.accompany')
          this.$notify({group: 'alert',type: 'warn',title: 'Incomplete Checkin',text: remaing })
        }else{
          this.submit()
        }
      }else{
        this.submit()
      }
    },
    async submit(){
      this.awaitResponse = true
      await this.$store.dispatch("getAuthToken", this.activeReservation).then(() =>{
          this.$store.dispatch("updateReservation", this.activeReservation).then(() => {
              this.awaitResponse = false
          }).catch(err => {
            this.awaitResponse = false
            this.$notify({group: 'alert',type: 'error',title: 'Error',text: err.message})
          })
      })
    }
  }
};
</script>
