import axios from 'axios';
import qs from 'qs';

class AuthService {
    getAuth() {
      const basicHeader = {'Content-Type':'application/x-www-form-urlencoded'}
      const data = qs.stringify({grant_type:"client_credentials",client_id:process.env.VUE_APP_CLIENT_ID,client_secret:process.env.VUE_APP_CLIENT_SECRET});
      const headers = {headers:basicHeader}
      return axios
      .post('/authenticator/oauth/token', data, headers)
        .then(response => {
          if (response.data.access_token) {
            localStorage.setItem('token', response.data.access_token);
          }
          return response.data;
        });
    }
  }
  
  export default new AuthService();