<template>
    <v-container class="mb-12">
        <privacy-item
        v-for="privacy in privacies"
        v-bind:key="privacy.privacyType"
        v-bind:privacy="privacy"
        ></privacy-item>
    </v-container>
</template>

<script>
import PrivacyItem from "../components/PrivacyItem";
import { mapGetters } from "vuex";
export default {
    data: () => ({

    }),
    computed: {
        ...mapGetters(["privacies"]),
    },
    components: {
      PrivacyItem,
  },
}
</script>