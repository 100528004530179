<template>
  <v-app-bar app color="white" height="100px">
    <v-container class="d-flex justify-start">
    <div>
      <v-img
        v-if="logo.local"
        alt="NP Logo"
        contain
        min-width="200"
        src="../assets/nplogo.png"
        width="200"
        />
      <v-img
        v-else
        alt="NP Logo"
        contain
        height="100"
        max-width="300"
        :src="logo"
      />
    </div>
    </v-container>
      <v-container class="d-flex justify-end">
        <div>
          <SelectLocale />
        </div>
      </v-container>
    <v-spacer></v-spacer>
  </v-app-bar>
</template>

<script>
import SelectLocale from './LocaleSelector.vue'
export default {
  props: ["logo"],
  components: {
    SelectLocale
  }
};
</script>