/* eslint-disable no-unused-vars */
import Vue from 'vue'
import BackendService from '../../../service/backend.service';

const state = {
    countries: null,
}

// getters
const getters = {
    location: state => {
        return state;
    }
}

const actions = {
    loadCountries(context,code){
        return BackendService.get('/m2m/location/countries/all',true).then(
            data => {
              context.commit('setCountries', data.data);
              return Promise.resolve(data.data);
            },
            error => {
            context.commit('showMessage',{type:'error',message:error.response.data.message})     
              return Promise.reject(error);
            }
        );
    }   
}


// mutations
const mutations = {
    setCountries(state,data) {
        state.countries = data
       
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}