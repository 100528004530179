import axios from 'axios'
import AuthHeader from './auth-header';

class BackendService {
    get(url,auth,fullUrl,resType) {
      var config = null;
      var path = process.env.VUE_APP_API_URL +url;
      if(resType){
        if (auth){
          config = {
              headers:AuthHeader.getFullAuthHeader(),
              responseType:resType
          }      
        }
      }else{
       config = {headers:AuthHeader.getFullAuthHeader()}      
      }
      if(fullUrl){
        path = url
      }
      return axios
      .get(path,config)
        .then(response => {
          return response;
        });
    }

    save(url,auth,object,dataType) {
      var headers = null;
      if(dataType){
        if (auth){
          var headAuth = {'Authorization':AuthHeader.getToken(),'Content-Type':dataType} 
          headers = {headers:headAuth}
        }else{
          headers = {headers:AuthHeader.getFullAuthHeader()}
        }
      }
      return axios
      .post(process.env.VUE_APP_API_URL + url,object,headers)
        .then(response => {
          return response;
        });
    }
    
    update(url,auth,object,dataType) {
      var headers = null;
      if(dataType){
        if (auth){
           var headAuth = {'Authorization':AuthHeader.getToken(),'Content-Type':dataType} 
          headers = {headers:headAuth}
        }else{
          headers = {headers:AuthHeader.getFullAuthHeader()}
        }
      }
      return axios
      .put(process.env.VUE_APP_API_URL + url,object,headers)
          .then(response => {
          return response;
          });
    }

    remove(url,auth) {
      var headers = null;
      if(auth){
        headers = {headers:AuthHeader.getFullAuthHeader()}
      }
      return axios
      .delete(process.env.VUE_APP_API_URL + url,headers)
          .then(response => {
          return response;
          });
      }
  }
  
export default new BackendService();