<template>
  <div class="container">
      <navigation v-bind:logo="'data:image/jpeg;base64,'+facilityLogo"/>
      <v-card elevation="3" style="text-align: center;"> <br/>
      <br/><h1>{{$t('commons.webcheckinSuccess')}}</h1> <br/>
      <br/></v-card>
      <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          Servizi Aggiuntivi
        </v-card-title>
        <v-card-text>Vuoi usufruire dei servizi aggiuntivi di cui la struttura dispone?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="dialog = false"
          >
            No
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="goToAdditionalForms()"
          >
            Si
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import Navigation from '../components/Navigation';
export default {
   computed: {
    ...mapGetters(["facilityConfig","forms"]),
   },
   data(){
     return{
       dialog: false,
       facilityLogo: localStorage.getItem("logo"),
     }
   },
   components: {
    Navigation
  },
  methods:{
    goToAdditionalForms(){
      this.$router.push({name:'additionalForms'}); 
    }
  },
  mounted() {
    if (this.forms.length > 0) {
      this.goToAdditionalForms()
    }
  },
};

</script>

<style>
</style>