/* eslint-disable no-unused-vars */
import Vue from 'vue'
import BackendService from '../../../service/backend.service';

const state = {
    facilityConfig: null,
    facilityService: null,
    steps: null,
    forms: null,
    fieldsRules: null,
    privacy:[]
}
// getters
const getters = {
    facilityConfig: state => {
        return state.facilityConfig;
    },
    facilityPrivacy: state => {
        return state.privacy;
    },
    fieldRules: state => {
        return state.fieldRules;
    },
    steps: state => {
        return state.steps;
    },
    forms: state => {
        return state.forms;
    },
    facilityService: state => {
        return state.facilityService;
    },
}

const actions = {
    loadFacilityConfig(context,code){
        return BackendService.get('/facility/facilityCode/' + code,true).then(
            data => {
              context.commit('setFacilityConfig', data.data);
              return Promise.resolve(data.data);
            },
            error => {
            context.commit('showMessage',{type:'error',message:error.response.data.message})     
              return Promise.reject(error);
            }
        );
    },
    loadFacilityPrivacy(context,code){
        return BackendService.get('/facility/facilityPrivacy/facilityCode/' + code.code + "/" + code.language,true).then(
            data => {
              context.commit('setFacilityPrivacy', data.data);
              return Promise.resolve(data.data);
            },
            error => {
            context.commit('showMessage',{type:'error',message:error.response.data.message})     
              return Promise.reject(error);
            }
        );
    },
    // loadFacilityForms(context,code){
    //     return BackendService.get('/facility/facilityForms/facilityCode/' + code,true).then(
    //         data => {
    //           context.commit('setFacilityForms', data);
    //           return Promise.resolve(data);
    //         },
    //         error => {
    //         context.commit('showMessage',{type:'error',message:error.response.data.message})     
    //           return Promise.reject(error);
    //         }
    //     );
    // },
    loadFacilityServices(context,code){
        return BackendService.get('/facility/service/facilityCode/' + code,true).then(
            data => {
              context.commit('setFacilityForms', data);
              return Promise.resolve(data);
            },
            error => {
            context.commit('showMessage',{type:'error',message:error.response.data.message})     
              return Promise.reject(error);
            }
        );
    },
    loadAdditionalForms(context,code){
        return BackendService.get('/facility/facilityForms/facilityCode/'+ code.code +"/"+ code.language,true).then(
            data => {
                data.data.forEach(function(element){
                    if(element != null){
                      element.formSchema = JSON.parse(element.formSchema)
                      element.formModel = JSON.parse(element.formModel)
                    }
                });
              context.commit('setFacilityForms', data);
              return Promise.resolve(data);
            },
            error => {
            context.commit('showMessage',{type:'error',message:error.response.data.message})     
              return Promise.reject(error);
            }
        );
    }                       
}


// mutations
const mutations = {
    setFacilityConfig(state,data) {
        localStorage.setItem('logo', data.logo);
        state.facilityConfig = data
        state.steps = JSON.parse(data.webCheckinConfiguration.steps)
        state.fieldsRules = JSON.parse(data.webCheckinConfiguration.fields)
    },
    setFacilityPrivacy(state,data) {
        state.facilityPrivacy = data
    },
    setFacilityForms(state,data) {
        state.forms = data.data
    },
    setFacilityService(state,data) {
        state.facilityService = data
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}