<template>
  <div class="container">
    <loading :active.sync="awaitResponse" 
              :is-full-page="fullPage"/>
    <v-container>
       <v-container>
        <v-col cols="12" md="8"  offset-md="2">
          <v-combobox
          v-model="selectedServices"
          :items="forms"
          item-text="name"
          label="Select service"
          multiple
          chips
          @change="showServiceForm"
        ></v-combobox>
        </v-col>
      </v-container>
      <v-row>
      <v-col cols="12" md="8" offset-md="2">
      <v-form v-model="valid">
        <v-container v-for="item in forms" :key="item.id" >
          <v-card v-if="showServiceForm(item)" elevation="2" >
            <v-card-title>{{item.name}}</v-card-title>
            <v-card-subtitle>{{item.description}}</v-card-subtitle>
            <v-card-text>
              <v-container>
                  <v-jsf v-model="item.formModel" :schema="item.formSchema"/>
              </v-container>
            </v-card-text>
          </v-card>
        </v-container>
      </v-form>
      </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import VJsf from '@koumoul/vjsf/lib/VJsf.js'
import '@koumoul/vjsf/lib/VJsf.css'
import '@koumoul/vjsf/lib/deps/third-party.js'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  mounted() {
   this.loadForms()
  },
  components: {
    VJsf,
    Loading,
  },
 computed: {
   
   },
  data() {
    return {
      valid: false,
      selectedServices: [],
      fullPage:true,
      awaitResponse: false,
      isLoading: false,
      forms:[]
    };
  },
  methods: {
    showServiceForm(code){
      return this.selectedServices.find(f => f.code == code.code)?true:false
    },
    async loadForms(){
        console.log(this.$i18n.locale)
        let obj = new Object()
        obj.code = 999
        obj.language = this.$i18n.locale
        await this.$store.dispatch("getAuthToken", this.activeReservation).then(() =>{
          this.$store.dispatch("loadAdditionalForms", obj).then((res) =>{
            this.forms=res.data
        })
      })  
    }
  }
};
</script>
