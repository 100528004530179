<template>
  <div class="container">
    <loading :active.sync="awaitResponse" :is-full-page="fullPage"/>
    <navigation v-bind:logo="logo"/>
    <v-card elevation="2">
      <v-card-text>
        <v-row class="justify-center">
          <p class="text-center">{{$t('transaction.completeTransaction')}}<br>
            {{$t('transaction.insertOtpCode')}}
            {{$t('transaction.otpCodeExpiration')}}</p>
        </v-row>
        <v-row class="justify-center">
          <v-otp-input
                inputClasses="otp-input"
                :numInputs="6"
                :isInputNum="true"
                separator="-"
                :shouldAutoFocus="true"
                @on-complete="handleOnComplete"
              />
        </v-row>
      </v-card-text>
        <v-row class="justify-center">
          <v-col sm="12" class="d-flex justify-center">
            <v-btn depressed color="#D9B767" class="white--text" @click="requestOtp">{{$t('transaction.newOtpCode')}}</v-btn>
          </v-col>
        </v-row>
         <v-row class="justify-center">
          <v-col sm="12" class="d-flex justify-center">
            <label>{{sentStatus}}</label>
          </v-col>
        </v-row>
    </v-card>
    <div class="row d-flex justify-center">
    
    </div>
  </div>
</template>

<script>
import Navigation from '../components/Navigation';
import Loading from 'vue-loading-overlay';
export default {
  name: "Transaction",
  components: {
    Navigation,
    Loading,
  },
  data(){
    return{
      fullPage:true,
      awaitResponse: false,
      logo:{
        local: true,
        logo: null,
      },
      sentStatus: null,
      transaction:{
        transactionId: true,
        otp: null,
      },
    }
  },
  mounted(){
    if (this.$route.params.transactionId) {
      this.transaction.transactionId = this.$route.params.transactionId
    } else {
      this.$router.replace({name: "Error404"})
    }
  },
  methods: {
    handleOnComplete(value) {
      this.submit(value)
    },
    async submit(value){
      this.awaitResponse = true
      this.transaction.otp = value
      await this.$store.dispatch("getAuthToken", this.reservation).then(() =>{
        this.verifyTransaction()
      }).catch(err => {
          this.awaitResponse = false
          this.$notify({group: 'alert',type: 'error',title: 'Error',text: err.message})
        })
    },
    async verifyTransaction(){
      await this.$store.dispatch("verifyTransaction", this.transaction).then(() =>{  
        this.awaitResponse = false
      }).catch(err => {
          this.awaitResponse = false
          this.$notify({group: 'alert',type: 'error',title: 'Error',text: err.message})
        })
    },
    async requestOtp(){
      await this.$store.dispatch("getAuthToken", this.reservation).then(() =>{
        this.$store.dispatch("sendNewOtpTransaction", this.transaction).then(() =>{
          this.sentStatus = "Sent"
        }).catch(err => {
          this.sentStatus = null
          this.$notify({group: 'alert',type: 'error',title: 'Error',text: err.message})
        })
      })
    },
  }
}
</script>
<style>
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: "center";
}
.error {
  border: 1px solid red !important;
}
</style>

